/**********************
 * Animated background
 *********************/

* {
  z-index: 2;
}

canvas {
  display: block;
  vertical-align: bottom;
  z-index: -1;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(20, 27, 37);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
#content {
    pointer-events: none;
}
a, button {
    pointer-events: all;
}
.is-top {
  z-index : 99999;
}

.is-horizontal-center {
  justify-content: center;
}

.is-transparent {
  background: rgba(0, 0, 0, 0);
}
